.container {
  position: absolute;
  top: 50%;
  left: calc(100% + 13px);
}

.text {
  color: var(--mantine-color-gray-7);
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: var(--mantine-font-size-textLg);
  font-style: normal;
  font-weight: var(--mantine-font-weight-regular);
  line-height: 1.5;
  text-wrap: nowrap;

  position: absolute;
  top: 100%;
  margin-left: 34px;
}
