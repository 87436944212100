.container {
  @media (min-width: $mantine-breakpoint-sm) {
    height: 400px;
  }
}

.child {
  width: 100%;
  min-height: 220px;
  position: relative;

  @media (min-width: $mantine-breakpoint-sm) {
    flex: 1;
    width: 50%;

    position: relative;
  }
}

.image {
  max-height: 100%;
  max-width: 100%;
  width: auto;

  border: 40px solid
    color-mix(in srgb, var(--mantine-color-gray-1) 80%, transparent);
  border-radius: 24px;
}
