.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.disabled::after {
  content: '';
  position: absolute;
  inset: 0;
  background-color: color-mix(
    in srgb,
    var(--mantine-color-gray-0) 30%,
    transparent
  );
}

.header {
  justify-content: flex-start;
}

.badge {
  position: absolute;
  transform: translate(20%, 25%) rotate(35deg);

  right: 0;
  top: 0;


  border-radius: 50px;
  border: 2px solid var(--mantine-color-primary-6);

  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 8px;
  padding-right: 8px;

  background-color: white;

  z-index: 1;

  --shadow-color: 0deg 0% 68%;
  --shadow-elevation-medium:
    0.3px 0.5px 0.6px hsl(var(--shadow-color) / 0.44),
    1.2px 2.3px 2.8px -1.3px hsl(var(--shadow-color) / 0.42),
    4.6px 9.3px 11.4px -2.7px hsl(var(--shadow-color) / 0.4);
  box-shadow: var(--shadow-elevation-medium);
}
