.buttonContainer {
  width: fit-content;
  position: relative;
}

.text {
  color: var(--mantine-color-gray-7);
  text-align: center;
  font-feature-settings: 'salt' on;
  font-family: Indie Flower;
  font-size: var(--mantine-font-size-displayXs);
  font-style: normal;
  font-weight: var(--mantine-font-weight-regular);
  line-height: 1.5;

  @media (min-width: $mantine-breakpoint-sm) {
    width: 50%;
  }
}
