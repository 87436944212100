.container {
  width: 100%;

  position: relative;
}

.premiereProVideo,
.autocutVideo {
  border-radius: 8px;

  animation: 0.5s ease-in-out 0s forwards appear;
}

.premiereProVideo {
  width: 110%;

  box-shadow: var(--mantine-shadow-3xl);
}

.autocutVideo {
  position: absolute;
  top: -30px;
  left: -50px;

  height: 100%;

  box-shadow:
    rem(0px) rem(8px) rem(8px) rem(-4px) rgba(199, 199, 199, 0.03),
    rem(0px) rem(20px) rem(24px) rem(-4px) rgba(199, 199, 199, 0.08);
}

@keyframes appear {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
