.badge {
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translateX(20%) translateY(20%);
}

.text {
  font-family: 'Ubuntu', sans-serif;
}
